.div-progress {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0vh !important;
}
.title-progress {
  display: flex;
  align-items: center;
  /* margin-top: 50px; */
  margin-right: 30px;
  cursor: pointer;
}
[dir="ltr"] .title-progress img{
  transform: scaleX(-1);
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiPaper-elevation0 {
  width: 90%;
}

.MuiStepLabel-iconContainer {
  display: none !important;
}
.img2 {
  display: none;
}
.step-blog-active-2{
  position: absolute;
  width: 100%;
  /* right: 20%; */
  z-index: 4;
}
[dir="ltr"] .step-blog-active-2{
  position: absolute;
  width: 100%;
  /* right: 20%; */
}
.button-next-step,
.button-finish-step {
  display: block !important;
  background: var(--primary-color) !important;
  padding: 15px 10px !important;
  width: 150px !important;
  color: #fff !important;
}
.button-back-step {
  display: block !important;
  background: rgba(29, 111, 184, 0.16) !important;
  padding: 15px 10px !important;
  width: 150px !important;
  color: var(--primary-color) !important;
  cursor: pointer;
}
.img-left-certifcation-payment{
  width: 90%;
  height: 350px;
}
.img-left-certifcation-payment img{
  width: 100%;
  height: 100%;
}

.step-box .MuiStepper-horizontal{
  background: transparent !important;
}
/* .finish-reset-create-course {
  text-align: center;
} */
.finish-reset-create-course p {
  font-size: 30px;
  color: var(--primary-color) !important;
  margin-bottom: 20px;
}
.create-passenger {
  position: relative;
  top: 1%;
}
@media (min-width: 768px) and (max-width: 992px) {
  .button-step-next-back-create-certifcation {
    width: 100% !important;
  }
  .step-blog-active-2{
    position: absolute;
    width: 75%;
    right: 25%;
    top: 11vh;
  }
  [dir="ltr"] .step-blog-active-2 {
    position: absolute;
    width: 75%;
    right: 0%;
    /* top: 11vh; */
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .step-blog-active-2{
    position: absolute;
    width: 75%;
    right: 25%;
    top: 11vh;
  }
  [dir="ltr"] .step-blog-active-2 {
    position: absolute;
    width: 75%;
    right: 0%;
    top: 11vh;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  [dir="ltr"] .step-blog-active-2 {
    position: absolute;
    width: 75%;
    right: 13%;
    top: 11vh;
  }


}
  