.notranslate {
    background-color: #ffff !important;
    margin-top: 0px !important;
    height: 40vh !important;
    overflow-y: scroll !important;


}
.div-btn-submit {
    display: flex;
    justify-content: center;
    align-items: center;
}
.div-btn-submit .bottonFullwidth{
    width: 10% !important;
    margin-top: 2% !important;

}