.containere{
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    position: relative;
    text-align: center;
  }
  
  .cog-wheel1, .cog-wheel2{
    transform:scale(0.7);
  }
  
  .cog1, .cog2{
    width:40vmin;
    height:40vmin;
    border-radius:50%;
    border:6vmin solid #f3c623;
    position: relative;
  }
  
  
  .cog2{
    border:6vmin solid #03314b;
  }
  
  .top, .down, .left, .right, .left-top, .left-down, .right-top, .right-down{
    width:10vmin;
    height:10vmin;
    background-color: #f3c623;
    position: absolute;
  }
  
  .cog2 .top,.cog2  .down,.cog2  .left,.cog2  .right,.cog2  .left-top,.cog2  .left-down,.cog2  .right-top,.cog2  .right-down{
    background-color: #03314b;
  }
  
  .top{
    top:-15vmin;
    left:16vmin;
  }
  
  .down{
    bottom:-14vmin;
    left:13vmin;
  }
  
  .left{
    left:-13vmin;
    top:14vmin;
  }
  
  .right{
    right:-14vmin;
    top:16vmin;
  }
  
  .left-top{
    transform:rotateZ(-45deg);
    left:-5vmin;
    top:-7vmin;
  }
  
  .left-down{
    transform:rotateZ(45deg);
    left:-7vmin;
    top:33vmin;
  }
  
  .right-top{
    transform:rotateZ(45deg);
    right:-7vmin;
    top:-5vmin;
  }
  
  .right-down{
    transform:rotateZ(-45deg);
    right:-6vmin;
    top:35vmin;
  }
  
  .cog2{
    position: relative;
    left:-10.2vmin;
    bottom:10vmin;
  }
  
  h1{
    color:#03314b;
  }
  
  .first-four{
    position: relative;
    left:-6vmin;
    font-size:40vmin;
  }
  
  .second-four{
    position: relative;
    right:1vmin;
    z-index: -1;
    font-size:40vmin;
    color: #f3c623 !important ;
  }
  
  .wrong-para{
    font-family: "Montserrat", sans-serif;
    position: absolute;
    bottom:15vmin;
    padding:3vmin 12vmin 3vmin 3vmin;
    font-weight:600;
    color:#092532;
    font-size: 30px;
  }