/* @font-face {
  font-family: "beIN";
  src: local("beIN"),
    url("../public/fonts/beIN-Normal-Normal.ttf") format("truetype");
  font-weight: bold;
} */
:root {
  --primary-color: #f9b917;
  /* --primary-color:#CD090A */
  --secound-color: #03314b;
  /* --third-color:#F9B917 */
}
/* html {
  scroll-behavior: smooth !important;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) #fff;
} */
::-webkit-scrollbar {
  width: 5px !important;
  height: 15px;
}

::-webkit-scrollbar-track-piece {
  background-color: #c2d2e4;
}

::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: var(--primary-color);
}

.path-station-container {
  display: flex;
  flex-direction: row-reverse;
}
.error-message {
  color: "red !important";
}
.icon-container > button svg {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.css-733vbh-MuiButtonBase-root-MuiListItemButton-root {
  /* padding-left: 16px !important; */
  /* padding-top: 20px !important; */
  padding-right: 40px !important;
  margin-top: 20px !important;
  margin-right: 15px !important;
  min-width: 0 !important;
  box-sizing: border-box !important;
  text-align: left !important;
  /* padding-bottom: 8px !important; */
  align-items: center !important;
  position: relative !important;
  justify-content: flex-start !important;
  display: flex !important;
}

.box-permission .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(-200px, 16px) scale(1);
}
.app {
  height: 100vh;
  width: 100%;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  background-color: #fff !important;
  color: #000;
}

.app {
  display: flex;
  flex-direction: column;
}

.headerSidebar {
  margin: 10px 0 0 0 !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
span {
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
}

.css-1dymhqc {
  padding-top: 15px !important;
}

.setsButton > button {
  padding: 0 130px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
}

.setsButton > button:hover {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 400;
  border: 1px solid var(--primary-color);
  font-family: "Roboto",Helvetica,Arial,sans-serif;
}

.setsColor {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px !important;
}

.BusRed {
  width: 100%;
}

.imgBus {
  width: 85px !important;
}

.setsButtonDivide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#root {
  direction: rtl !important;
}

.pro-sidebar > .pro-sidebar-inner {
  /* border-top-left-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px; */
  /* overflow-y: auto; */
}

.pro-sidebar > .pro-sidebar-inner {
  position: fixed !important;
}

.pro-sidebar {
  text-align: right !important;
  /* Set a maximum height for the container */
  /* overflow-y: auto; Add vertical scroll when content overflows */
  scrollbar-color: var(--primary-color) transparent !important;
  scroll-behavior: smooth !important;
  scrollbar-width: thin !important;
}
.content {
  width: 100% !important;
  /* overflow: auto; */
  padding: 0 1% !important;
  height: 90vh;
  margin-top: 2vh !important;;

  /* height: 92vh !important; */
  /* margin-top: 10vh !important; */
}
.custom_sidebar {
  /* width: 100% !important; */
  /* background: var(--secound-color) !important; */
}
.pro-sidebar {
  width: 100% !important;
  min-width: 100% !important;
  background: var(--secound-color) !important;
}

.css-1656tn2-MuiList-root {
  background-color: #000002 !important;
}

.css-1m636nl .pro-sidebar-inner {
  width: 250px;
}

.css-tlelie-MuiListItemText-root {
  flex: none !important;
}

.css-upkf82-MuiButtonBase-root-MuiListItemButton-root {
  padding-right: 40px !important;
}

/*start sidebar*/
.pro-sidebar .pro-menu a:hover {
  background-color: var(--primary-color);
  width: 15%;
  margin: 0px !important;
}
.pro-sidebar .pro-menu ul {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.pro-sidebar .pro-menu a.active {
  background-color: var(--primary-color);
  margin: 0px !important;
  width: 15%;
}
.pro-sidebar .pro-menu a {
  margin: 0px !important;
  width: 15% !important;
  padding: 0px !important;
  height: 8vh !important;
}
.pro-sidebar .pro-menu a .MuiListItemText-root {
  width: 100% !important;
  display: block !important;
}
.pro-sidebar-layout {
  flex-direction: row !important;
}
.pro-sidebar .pro-menu a .MuiListItemText-root span {
  text-align: center !important;
}

.pro-sidebar {
  width: 100vh;
  height: 8vh !important;
}
.pro-sidebar-inner {
  width: 100% !important;
}

.css-1ald77x.Mui-focused {
  color: var(--primary-color) !important ;
}
.makeStyles-mainButton-16 {
  height: 0 !important;
  padding: 20px 30px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  min-width: 0 !important;
}

.makeStyles-filter-8.MuiBox-root.css-0
  > .MuiPaper-root.ForwardRef-root-9.makeStyles-searchDesign-7.MuiPaper-elevation1.MuiPaper-rounded {
  margin-top: 0 !important;
  margin-left: 15px !important;
}

.ForwardRef-searchIconButton-12 {
  margin-right: 0px !important;
}

.css-al7dvp-MuiTypography-root {
  width: calc(100vw - 270px) !important;
}

.ForwardRef-searchIconButton-6 {
  margin-right: -140px !important;
}

.MuiPaper-rounded {
  /* border-radius: 10px !important; */
}

.ForwardRef-root-3 {
  flex-direction: row-reverse !important;
}

input::placeholder {
  color: white !important;
}

.ForwardRef-root-3 {
  height: 40px !important;
}

/* end search */

/*start Filter*/
[dir="ltr"] .dropdown-form-two {
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  text-align-last: center;
}

[dir="ltr"] .dropdown-form-one {
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  text-align-last: center;
  padding-right: 10px;
}

.finalRes {
  text-align: center;
}

.body-container-complaint span {
  color: #000002;
  font-size: 14px;
  font-weight: normal;
}

.divtitleone1,
.divtitletwo2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.divtitlethree3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column !important;
}
.checkreservation
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: var(--primary-color) !important;
}
.bodycomplaint {
  color: #828282;
}

.titleReservation {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-weight: 10 !important;
}

.cancel {
  color: white !important;
  margin-left: 20px !important;
}
.popupcomany {
  margin: 33px 0px 18px;
  display: flex;
  flex-direction: row-reverse;
}
.cancel:hover {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.dropdown-form {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
  padding: 0px 0px 15px 15px;
  position: relative;
  z-index: 2;
}

.dddd {
  width: 100px;
}

/*end Filter*/
.MuiInputLabel-formControl {
  direction: rtl !important;
  text-align: right !important;
  right: 8% !important;
  transform-origin: top right !important;
}

.MuiOutlinedInput-root:hover,
.MuiOutlinedInput-notchedOutline {
  border-color: #fff;
}

.logindiv > div > div .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.logindiv > input {
  background: #ebebeb;
  border: 1px solid #ebebeb !important;
  height: 15px !important;
}

.css-1tsvksn {
  margin-bottom: 4px;
  min-width: 0px;
  margin-top: 4px;
  flex: none !important;
}

.css-1ujsas3 {
  min-width: 0px !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(3) {
  display: none !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(4) {
  display: none !important;
}

.MuiDataGrid-main.css-opb0c2 > div:nth-child(3) {
  display: none !important;
}

.MuiDataGrid-main.css-opb0c2 > div:nth-child(4) {
  display: none !important;
}

.css-13sljp9 {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.css-8l6tpu {
  margin-right: 20px !important;
}

.css-7ozjwe {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  z-index: 1 !important;
}

.css-fivdzo {
  min-width: 0 !important;
}

.filter1 {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.filter1 > div {
  border-radius: 10px !important;
  height: 50px;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.select .select-text {
  appearance: none;
  -webkit-appearance: none;
}

#demo-simple-select {
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  padding: 0px !important;
  height: 38px;
}
.directselect{
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  padding: 0px !important;
  height: 38px;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
}

.MuiSelect-selectMenu {
  height: 9px !important;
}

.css-1e2bxag-MuiDataGrid-root {
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  padding: 4px 274px 38px !important;
}

.makeStyles-filter-8.MuiBox-root.css-0 > :nth-child(2)::before {
  border-bottom: none !important;
  right: 40px !important;
  bottom: 7px !important;
  content: "فلتر" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 15px;
}

.makeStyles-filter-8.MuiBox-root.css-0 > :nth-child(3)::before {
  border-bottom: none !important;
  right: 40px !important;
  bottom: 7px !important;
  content: "ترتيب" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 15px;
}

.contentDivide .MuiBox-root.css-0 > :nth-child(2)::before {
  border-bottom: none !important;
  right: 40px !important;
  bottom: 7px !important;
  content: "فلتر" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 15px;
}

.contentDivide .MuiBox-root.css-0 > :nth-child(3)::before {
  border-bottom: none !important;
  right: 40px !important;
  bottom: 7px !important;
  content: "ترتيب" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 15px;
}

.contentDivide .MuiBox-root.css-0 > :nth-child(5) {
  height: 40px !important;
  padding: 0 40px !important;
  /* font-weight: 600 !important; */
}

.contentDivide .MuiBox-root.css-0 > :nth-child(4) {
  margin-left: 15px !important;
}

.ForwardRef-root-9 {
  height: 40px !important;
  flex-direction: row-reverse;
}

.contentDivide .MuiBox-root.css-0 > :first-child {
  flex-direction: row-reverse;
  height: 40px !important;
  margin-left: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
}

.contentDivide .MuiBox-root.css-0 > :first-child > :first-child {
  margin: auto 16px !important;
}

.MuiIconButton-root {
  padding: 0 !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-focused.Mui-focused {
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
}

.makeStyles-filter-8.MuiBox-root.css-0 > :nth-child(2) {
  background-image: url("../public/img/filter.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.contentDivide .MuiBox-root.css-0 > :nth-child(2) {
  background-image: url("../public/img/filter.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.makeStyles-filter-8.MuiBox-root.css-0 > :nth-child(3) {
  background-image: url("../public/img/sort.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.contentDivide .MuiBox-root.css-0 > :nth-child(3) {
  background-image: url("../public/img/sort.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.MuiInput-underline::after {
  border-bottom: none !important;
}

.ForwardRef-iconButton-10 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 8px 8px !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08) !important;
}

.MuiDialog-container {
  direction: ltr !important;
  opacity: 1;
  transition: opacity 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiDialog-container > div {
  /* box-shadow: none !important; */
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.01) !important;
  opacity: 1;
  transition: opacity 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-1e2bxag-MuiDataGrid-root {
  border: none !important;
}

.reloadButton {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.reloadButton1 {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  /* width: 100%;
  height: 28px; */
  width: 89%;
  height: 38px;
  border-radius: 10px !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #6b6567 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  min-width: 40px !important;
  border-radius: 10px !important;
}

.MuiSelect-selectMenu {
  color: #6b6567 !important;
}

.makeStyles-dialogWrapper-15 {
  padding: 0 !important;
}

.MuiDialogContent-dividers {
  border-top: 1px solid var(--primary-color) !important;
  border-bottom: none !important;
  padding: 27px 16px !important;
  margin: 0px 37px;
}

.test {
  width: 500px !important;
}

.iconplaceholder {
  padding: 0 !important;
  width: 30px;
  height: 30px;
  margin-left: 16px !important;
}

.iconplaceholder img {
  width: 100%;
}

.placeholdertitle {
  color: #000 !important;
}

.placeholdertitle1 {
  color: #000 !important;
}

.tesr > div {
  border-radius: 8px !important;
}

.tesr1 {
  width: 10% !important;
}

.tesr1 > div {
  border-radius: 8px !important;
  margin-left: 5px !important;
}

.tesr122 {
  width: 100% !important;
}

.tesr122 > div {
  border-radius: 8px !important;
  margin-left: 5px !important;
}

.tesr12 {
  width: 100% !important;
}

.selecttop {
  margin-top: -81px !important;
}

.placeholdertitle1 {
  margin-right: 10px;
  color: #000 !important;
}

.tesr12 > div {
  border-radius: 8px !important;
  margin-left: 5px !important;
}

.tesr22 > div {
  background-color: #dadad7;
  border-radius: none !important;
}

.tesr22 .MuiOutlinedInput-notchedOutline {
  border: 1px solid #f7f7f7 !important;
}

.tesr22 label {
  font-size: 20px !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--primary-color) !important;
  font-size: 16px !important;
}

.button-dailog {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.button-dailog button:nth-child(1) {
  width: 200px;
  background-color: var(--primary-color);
  margin-left: 10px;
}

.button-dailog button:nth-child(1):hover {
  background-color: var(--primary-color) !important;
}

.button-dailog button:nth-child(2) {
  background: #fff;
  color: var(--primary-color);
  width: 200px;

  border: 1px solid var(--primary-color);
  padding: 8px 20px !important;
}

.box-button {
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}

.button-dailog1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.button-dailog1 button:nth-child(1) {
  background-color: var(--primary-color);
  width: 200px;
}

.button-dailog1 button:nth-child(2) {
  background: #fff;
  color: var(--primary-color);
  width: 200px;

  border: 1px solid var(--primary-color);
  padding: 10px 20px !important;
}

.bottonFullwidth {
  width: 100%;
}

.bottonFullwidth > button {
  width: 100%;
  background-color: var(--primary-color);
}

.bottonFullwidth > button:hover {
  width: 100%;
  background-color: var(--primary-color);
}

.MuiPaper-outlined {
  border: none !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.makeStyles-root-2 {
  min-width: 200px !important;
}

.css-a334ub-MuiTypography-root {
  max-width: 100% !important;
}

.css-a334ub-MuiTypography-root {
  margin-right: 0px !important;
}

.MuiButton-roo {
  min-width: 0 !important;
}

.MuiGrid-spacing-xs-4 {
  width: calc(100% + 0px) !important;
}

.MuiGrid-spacing-xs-2 {
  width: calc(100% + 0px) !important;
}

.MuiTypography-alignLeft {
  text-align: center !important;
}

.MuiGrid-spacing-xs-2 {
  margin: 0 !important;
}

.MuiTypography-h2 {
  font-size: 25px !important;
}

.css-a334ub-MuiTypography-root {
  margin-top: 30px !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  display: none !important;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
}

.MuiDataGrid-footerContainer {
  direction: ltr !important;
  /* display: none !important; */
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root,
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border-radius: 8px !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  /* #818E94 */
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root,
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  color: #818e94 !important;
}

.css-1oj2twp-MuiPagination-root {
  display: flex !important;
  justify-content: center !important;
}

.MuiPagination-ul {
  display: flex !important;
  direction: ltr !important;
  justify-content: center !important;
  align-items: center !important;
}

.MuiPagination-ul li:nth-child(1) {
  margin-right: 63px !important;
}

.MuiPagination-ul li:last-child {
  margin-left: 63px !important;
}

.App {
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  text-align: center;
}

.displayCard {
  text-align: center;
}

.card {
  max-width: 50%;
  display: inline-block;
  border: none;
}

.card-container {
  height: 362px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  transition: 0.5s;
  animation: ease-in-out;
  border-radius: 9px;
  position: relative;
}

.image-container {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-div {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: end;
}

.DateBirthday {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-container img {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

h3,
p {
  margin: 0;
  padding: 0;
}

.title-container h3 {
  font-size: 27px;
  font-weight: lighter;
  color: #000002;
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 54px 0px 54px;
}

.body-container span {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: normal;
}

.body-container img {
  margin-left: 5px;
  width: 14px;
}

.body-container1 {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  margin: 0 54px 12px 54px;
}
.body-container1 > p {
  margin-left: 10px;
}

.body-container1 span {
  color: #a7a7a7;
  font-size: 18px;
  font-weight: normal;
}

.body-container1 img {
  margin-left: 5px;
}

.body-container2 {
  margin: 0 10px 0px 0px;
}

.body-container2 span {
  color: #000;
  font-size: 20px;
  /* font-weight: bold; */
}

.body-container2 .divtitleone span img {
  width: 27px;
  height: 27px;
  position: relative;
  top: 8px;
}

.body-container2 .divtitletwo span {
  color: #000;
  font-size: 16px;
  font-weight: lighter;
}

.btn {
  display: flex;
  justify-content: center;
  margin: 0rem;
}

.btn button {
  padding: 0.5rem;
  background-color: white;
  border: none;
  transition: 0.2s;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}

.btn button :hover {
  background: rgba (27, 156, 252, 0.1);
}

a {
  text-transform: uppercase;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 830px) {
  .card {
    padding: 1%;
    display: inline-block;
  }
}

.MuiButton-root:hover {
  text-decoration: none !important;
  background-color: transparent !important;
}

.bottonFullwidthSchedular button.MuiButton-root:hover {
  background: var(--primary-color) !important;
}

.card-menu {
  position: absolute;
  left: 20px !important;
  top: 28px !important;
}

.card-menu1 {
  background-color: #fdfdfd !important;
  position: absolute;
  left: 0px !important;
  top: 8px !important;
}

.MuiInputBase-input {
  position: relative !important;
}

.MuiSelect-icon {
  position: absolute !important;
  left: 38px !important;
  fill: #000 !important;
}

.menu {
  padding: 23px 24px 19px 64px !important;
}

.menuStyle {
  direction: rtl !important;
}

.menuStyle img {
  margin-left: 10px;
}



.testnew div:nth-child(3) ul li img {
  width: 12px !important;
}

#mui-component-select-departmentId {
  padding: 10px 14px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--primary-color) !important;
}
.MuiSelect-selectMenu {
  color: black !important;
}
.MuiSelect-select.MuiSelect-select,
.MuiInputBase-input {
  direction: rtl !important;
}
.logo .MuiInputBase-input {
  direction: ltr !important;
}
.title-bus-type {
  font-size: 30px;
  color: var(--primary-color) !important;
}
.box-notic {
  font-size: 20px;
  line-height: 2;
  margin-bottom: 20px;
}
#demo-simple-select {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100% !important;
  padding-right: 20px !important;
}
.directselect {
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;
  padding-right: 20px !important;
}
.icon-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 50px;
}
#demo-simple-select-label,
#demo-simple-select-label1 {
  width: 100% !important;
}
.makeStyles-secondary-109 .MuiButton-label,
.makeStyles-secondary-19 .MuiButton-label,
.makeStyles-secondary-27 .MuiButton-label,
.makeStyles-secondary-43 .MuiButton-label,
.makeStyles-secondary-129 .MuiButton-label,
.makeStyles-secondary-193 .MuiButton-label,
.makeStyles-secondary-224 .MuiButton-label,
.makeStyles-secondary-232 .MuiButton-label,
.makeStyles-secondary-267 .MuiButton-label,
.makeStyles-secondary-275 .MuiButton-label,
.makeStyles-secondary-283 .MuiButton-label,
.makeStyles-secondary-39 .MuiButton-label,
.makeStyles-secondary-47 .MuiButton-label,
.makeStyles-secondary-56 .MuiButton-label {
  background-color: var(--primary-color) !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: 3.438em !important;
}
#mui-component-select-departmentId1 {
  padding: 8px 9px !important;
}
.MuiOutlinedInput-notchedOutline #demo-form-control-label-placement {
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  color: #000 !important;
  line-height: 2.438em !important;
}

.css-1nrlq1o-MuiFormControl-root {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart.css-1f2kvjf-MuiFormControlLabel-root {
  margin-right: 20px !important;
}

.MuiPaper-elevation8 {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
}

.iconplaceholder12 {
  border: 1px solid #fff !important;
}

.MuiDialogContent-dividers {
  border-top: 3px solid var(--primary-color) !important;
  border-bottom: none !important;
  padding: 27px 16px !important;
  margin: 0px 37px;
}

.MuiDialogContent-dividers1 {
  padding: 27px 16px !important;
  margin: 0px 37px;
}

.tesr > div {
  border-radius: 8px !important;
}

.tesrR > div {
  border-radius: 8px !important;
}

.tesrRR input {
  padding: 18.5px 14px;
}

.tesrRR label {
  top: 10px !important;
}

.tesrRR
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
  margin-top: 0 !important;
}

.tesrRR .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: var(--primary-color) !important;
  font-size: 35px !important;
  top: 10px;
  position: relative;
  right: 360px;
}

.tesrRR > div {
  border: 1px solid var(--primary-color) !important;
}

.tesrRR .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  background-color: #fff !important;
  color: #fff !important;
}

.tesrRR input {
  direction: rtl !important;
}

.tesrRR Button {
  margin-right: 10px !important;

  margin-top: -17px !important;
}

.selectraduis {
  border-radius: 8px !important;
}

.button-dailog {
  display: flex;
  justify-content: space-around;
  align-items: center;
  direction: rtl !important;
  width: 60%;
  gap: 1%;
}

.button-dailog button:nth-child(1) {
  background-color: var(--primary-color);
  width: 200px;
}

.button-dailog button:nth-child(2) {
  background: #fff;
  color: var(--primary-color);
  width: 200px;

  border: 1px solid var(--primary-color);
  padding: 8px 10px !important;
}

.bottonFullwidth {
  width: 100%;
}
.loadingupdate {
  width: 30%;
  padding: 0% 0;
}
.bottonFullwidth > button {
  width: 30%;
  background-color: var(--primary-color);
  border-radius: 8px;
}

.bottonFullwidth > button:hover {
  width: 30%;
  background-color: var(--primary-color) !important;
}

.bottonFullwidth1 {
  width: 85%;
  margin-bottom: 10px;
}

.bottonFullwidth1 > button {
  font-size: 13px;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
}

.bottonFullwidthSchedular {
  width: 20%;
  margin-left: 2%;
}

.bottonFullwidthSchedular > button {
  font-size: 15px;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
}
.form-control-selecte > label span {
  font-size: 14px;
}
.bottonFullwidthType {
  display: flex;
  justify-content: center;
  algin-item: center;
}
.bottonFullwidthType > button {
  font-size: 19px;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
}

.bottonFullwidth12 > button {
  font-size: 19px;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
  margin-top: 25px;
}

.bottonFullwidth123 > button {
  font-size: 19px;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
  margin-top: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  padding-left: 15px;
  padding-right: 15px;
  border: none !important;
}

.bottonFullwidth1 > button:hover {
  width: 100%;
  background-color: var(--primary-color) !important;
}

.bottonFullwidth2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottonFullwidth2 > button {
  width: 70% !important;
  background-color: var(--primary-color) !important;
}
.bottonFullwidth22 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottonFullwidth22 > button {
  width: 100% !important;
  background-color: #fff !important;
  color:var(--primary-color) !important;
  border:1px solid var(--primary-color) !important;
  border-radius:10px;
}

.bottonFullwidth22 > button:hover {
  border-radius:10px;
  width: 100% !important;
  background-color: #fdfdfd !important;
}
.bottonFullwidth2 > button:hover {
  width: 70% !important;
  background-color: var(--primary-color) !important;
}

.print1 {
  display: flex;
}

.travelTable {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  margin-right: 15px !important;
}
.box-catergory-permistion {
  padding: 10px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4) !important;
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--secound-color);
  font-size: 16px;
  border-radius: 10px;
}
.box-catergory-permistion1 {
  padding: 10px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7) !important;
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--secound-color);
  font-size: 15px;
  border-radius: 10px;
}
.pro-sidebar .pro-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 8vh !important;
  width: 80%;
}
.pro-sidebar .pro-menu a {
  color: #fff !important;
}
.li-permission {
  padding: 0 !important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.arrowButton {
  border: white;
  background: none;
  width: 33px;
  /* height: 30px;
  margin-top: 10%; */
}

.travelTable :nth-child(2) {
  margin-top: -6px !important;
}

.TravelTitle {
  margin-right: 10px;
}

.field-table2 :nth-child(2) {
  box-shadow: none !important;
}

.MuiPaper-outlined {
  border: none !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
}

.makeStyles-card-3 {
  margin: 0px !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 0px !important;
}

.css-35zqo5-MuiTabs-root {
  border-right: none !important;
  width: 100% !important;
}

.css-35zqo5-MuiTabs-root {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 12px 30px !important;
}

.css-10d9dml-MuiTabs-indicator {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  z-index: 1;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  z-index: 2 !important;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  background-color: #fff !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.MuiGrid-container {
  width: auto !important;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-lg-12
  > .MuiPaper-rounded {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.MuiGrid-spacing-xs-4 {
  margin: 0px !important;
}

.css-19kzrtu {
  padding-right: 15px !important;
  padding: 0 !important;
}

.MuiBox-root.css-jb84w7
  > .field-table.MuiDataGrid-root.MuiDataGrid-root--densityStandard.css-1e2bxag-MuiDataGrid-root {
  margin-top: 0 !important;
}

.MuiBox-root.css-zjqymt > .MuiBox-root.css-jb84w7 {
  margin-top: 0 !important;
}

.css-204u17-MuiDataGrid-main {
  /* margin-top: 20px !important; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
}

.css-jb84w7 .MuiDataGrid-footerContainer {
  background-color: transparent !important;
}

.css-jb84w7 .MuiDataGrid-columnHeaders {
  background-color: #ffffff !important;
}

.css-fivdzo {
  max-width: 100% !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(3) {
  display: none !important;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(4) {
  display: none !important;
}

.MuiDataGrid-row.MuiDataGrid-row--editable:nth-child(even) {
  background-color: #ffffff;
}

.MuiDataGrid-row.MuiDataGrid-row--editable:nth-child(odd) {
  background-color: #dbdbdb !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
  width: 100vw !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: transparent !important;
}

.selects {
  width: 200px !important;
  margin-left: 20px;
  line-height: 1.7em !important;
}

.selects .MuiSelect-icon {
  right: 173px !important;
  fill: #818e94 !important;
}

.selects1 {
  width: 97% !important;
  background-image: url("../public/img/sort.svg") !important;

  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects1trip {
  width: 97% !important;
  /* background-image: url("../public/img/sort.svg") !important; */

  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects11 {
  width: 97% !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects11trip {
  width: 97% !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects321 {
  width: 97% !important;
  background-image: url("../public/img/sort.svg") !important;

  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  margin-right: 6px;
  align-items: first baseline !important;
}
.selects3211 {
  width: 97% !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects21 {
  width: 97% !important;
  background-image: url("../public/img/sort.svg") !important;

  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;

  align-items: first baseline !important;
}
.selects23 {
  width: 80% !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  align-items: first baseline !important;
  margin-right: 10%;
}

.selects3 {
  width: 97% !important;
  background-image: url("../public/img/Send.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  background-size: 20px;
  display: flex !important;
  align-items: first baseline !important;
}

.stopPlaces {
  display: flex;
  flex-direction: row-reverse;
}
.makeStyles-secondary-31 .MuiButton-label {
  background: var(--primary-color) !important;
}
.selects4 {
  width: 97% !important;
  background-image: url("../public/img/Received.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  background-size: 20px;
  display: flex !important;
  align-items: first baseline !important;
}

.selects11 > div,
.selects1 > div {
  height: 38px !important;
}
.selects11trip > div,
.selects1trip > div {
  height: 38px !important;
}
.selects21 > div,
.selects23 > div {
  height: 38px !important;
}
.selects321 > div,
.selects3211 > div {
  height: 38px !important;
}

.selects3 > div {
  height: 38px !important;
}

.selects4 > div {
  height: 38px !important;
}

.selects1::before {
  border-bottom: none !important;
  right: 15% !important;
  bottom: 20% !important;
  content: "الانطلاق " !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects1trip::before {
  border-bottom: none !important;
  right: 15% !important;
  bottom: 20% !important;
  content: "الانطلاق " !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects11::before {
  border-bottom: none !important;
  right: 11% !important;
  bottom: 12px !important;
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects11trip::before {
  border-bottom: none !important;
  right: 20% !important;
  bottom: 12px !important;
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects321::before {
  border-bottom: none !important;
  right: 11% !important;
  top: 25% !important;
  content: "دور الموظف " !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects3211::before {
  border-bottom: none !important;
  right: 11% !important;
  /* bottom: 12px !important; */
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects21::before {
  border-bottom: none !important;
  right: 12% !important;
  bottom: 20% !important;
  content: "لوحة الباص " !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects23::before {
  border-bottom: none !important;
  right: 11% !important;
  bottom: 12px !important;
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}

.selects4::before {
  border-bottom: none !important;
  right: 20% !important;
  bottom: 20% !important;
  content: "الوجهة" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}

.selects3::before {
  border-bottom: none !important;
  right: 20% !important;
  /* bottom: 12px !important; */
  content: "الانطلاق" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}

.selects1 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}
.selects1trip .MuiSelect-icon {
  /* right: 85% !important; */
  fill: #818e94 !important;
}
.selects11 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}
.selects11trip .MuiSelect-icon {
  /* right: 85% !important; */
  fill: #818e94 !important;
}
.selects21 .MuiSelect-icon,
.selects23 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}
.selects321 .MuiSelect-icon,
.selects3211 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}

.selects3 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}

.selects4 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}

.selects2 {
  width: 97% !important;
  background-image: url("../public/img/sort.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  align-items: first baseline !important;
}
.selects2trip {
  width: 97% !important;
  /* background-image: url("../public/img/sort.svg") !important; */
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  align-items: first baseline !important;
}
.selects2.Mui-focused {
  background-image: none !important;
}
.selects2trip.Mui-focused {
  background-image: none !important;
}
.selects2 > div,
.selects22 > div {
  height: 38px !important;
}
.selects2trip > div,
.selects22trip > div {
  height: 38px !important;
}

.selects2::before {
  border-bottom: none !important;
  right: 15% !important;
  bottom: 20% !important;
  content: "الوجهة" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects2trip::before {
  border-bottom: none !important;
  right: 15% !important;
  bottom: 20% !important;
  content: "الوجهة" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selected-type-bus{
  margin-right:20px !important;
}
.button-reload-type-bus{
  margin-right:10px !important;
}

.selects2.Mui-focused::before {
  content: " " !important;
}
.selects2trip.Mui-focused::before {
  content: " " !important;
}
.selects22 {
  width: 97% !important;
  /* background-image: url("../public/img/sort.svg") !important; */
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  align-items: first baseline !important;
}
.selects22trip {
  width: 97% !important;
  /* background-image: url("../public/img/sort.svg") !important; */
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
  display: flex !important;
  align-items: first baseline !important;
}
.selects22.Mui-focused {
  background-image: none !important;
}
.selects22trip.Mui-focused {
  background-image: none !important;
}
.selects22 > div {
  height: 38px !important;
}
.selects22trip > div {
  height: 38px !important;
}

.selects22::before {
  border-bottom: none !important;
  right: 15% !important;
  /* bottom: 12px !important; */
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects22trip::before {
  border-bottom: none !important;
  right: 30% !important;
  /* bottom: 12px !important; */
  content: "" !important;
  color: #6b6567;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  font-size: 12px;
}
.selects22.Mui-focused::before {
  content: " " !important;
}
.selects22trip.Mui-focused::before {
  content: " " !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  display: inline-flex !important;
  font-size:12px !important;
}

.selects2 .MuiSelect-icon,
.selects22 .MuiSelect-icon {
  right: 85% !important;
  fill: #818e94 !important;
}
.selects2trip .MuiSelect-icon,
.selects22trip .MuiSelect-icon {
  /* right: 85% !important; */
  fill: #818e94 !important;
}

.MuiBox-root.css-0 h5 {
  font-size: 18px !important;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}

.makeStyles-root-18 .MuiOutlinedInput-notchedOutline {
  border: "1px solid #fff !important";
}

.btn-sidebare-d {
  margin-top: 0 !important;
}

.btn-sidebare-d:hover {
  background-color: transparent !important;
}

.travelstop {
  display: flex;
  margin-right: 0px;
  margin-top: 20px;
}

.retravelstop {
  display: flex;
  flex-direction: row;
}

.fontStop {
  font-size: 12px;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
}

.image-stop {
  height: 230px;
  margin-right: 54px;
  margin-top: 22px;
}

.button {
  background-color: black;
  border: none;
  color: white;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
}

.arrowblack {
  width: 100%;
  height: 100%;
}

.print {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--primary-color);
  flex-wrap: wrap;
}

.selectoption {
  margin-top: -81px !important;
}

.stopPlacement {
  margin-right: -36px !important;
  margin-top: 0 !important;
}

.stopPlacement:hover {
  margin-right: -36px !important;
  margin-top: 0 !important;
}

.gggggggg
  > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl
  > .MuiInputBase-input:focus {
  outline: 0;
  display: block;
  padding: 0ps;
  padding: 0;
  margin: 0;
  transform: none !important;
  animation-name: none;
  /* background-color: black; */
}

.createdTrip {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.traveldiv {
  margin: 30px 0px 50px 90px !important;
}

.traveldiv p {
  font-size: 25px !important;
}

.icontravel {
  width: 50px !important;
  margin-left: 10px !important;
}

.icontravel img {
  width: 100% !important;
}

.traveltwo {
  margin: 10px !important;
  display: flex;
  justify-content: center;
  align-content: center;
}

.travelthree {
  margin-bottom: 20px !important;
  display: flex;
  justify-content: center;
}

.travelthree p {
  font-size: 25px;
}

.travelsection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tesrtt label > div {
  display: flex;
  justify-content: space-between;
}

.travelsectionone {
  display: flex;
}
.box-permission {
  height: 80vh !important;
}

.css-lfwcke-MuiTabs-flexContainer {
  justify-content: center !important;
  align-items: center !important;
}

.travelsectiontwo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.travelsectionone {
  display: flex;
  justify-content: space-between;
}

.travelsectionone > div {
  width: 48% !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px !important;
}

.travelsectiontwo div {
  border-radius: 10px !important;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
}

.travelsectiontwo > div {
  width: 100% !important;
}

/* .travelsect div:nth-child(2) {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  height: none !important ;
} */
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 350px !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root,
.radiobranch
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root,
.checkboxcompanyy
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: var(--primary-color) !important;
  cursor: pointer;
}
.travelsect1 {
  display: flex;
  flex-direction: column;
  margin-top: 0px !important;
}

.sets {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}

.sets span {
  margin-right: 20px !important;
}
.divtitleone
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: var(--primary-color) !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #000 !important;
  direction: rtl !important;
}

.tesrR .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--primary-color) !important;
  direction: rtl !important;
}
#mui-component-select-departmentId .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--primary-color) !important;
  direction: rtl !important;
}
.FormColor11 fieldset {
  border: 1px solid var(--primary-color) !important;
  direction: rtl !important;
}

.radio-person > div {
  box-shadow: none !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.step-passenger {
  width: 100px;
  height: 100px;
}
.step-box {
  position: relative;
}
.step-box .step-passenger {
  position: relative;
}
.step-box .step-passenger > :nth-child(3) {
  position: absolute;
  top: 15%;
  right: 45%;
  font-size: 30px;
  font-weight: bolder;
}
.create-passenger {
  position: relative;
  top: 10%;
}
.button-passenger button,
.add-company button {
  background: var(--primary-color) !important;
}
.button-passenger button.MuiButton-root:hover,
.add-company button.MuiButton-root:hover {
  background: var(--primary-color) !important;
}
.bottonFullwidth3 {
  width: 20%;
}
.bottonFullwidth3 > button {
  background: var(--primary-color) !important;
}
.bottonFullwidth3 > button:hover {
  background: var(--primary-color) !important;
}
.step-passenger img {
  width: 100%;
  height: 100%;
}
.step-passenger span {
  width: 200px;
}
.travbox {
  box-shadow: none !important;
}
.passen
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled.MuiInputLabel-shrink {
  font-size: 10px !important;
  transform: translate(50px, -6px) scale(1) !important;
}
.imgs {
  display: flex;
}
.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersDay-daySelected {
  background-color: var(--primary-color) !important;
}
.MuiPickersClockPointer-thumb {
  border: 14px solid var(--primary-color) !important;
}
.MuiDialogActions-root .MuiButton-textPrimary {
  color: var(--primary-color) !important;
}
.css-1ehaqqx-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled,
.css-35zqo5-MuiTabs-root .MuiTabs-scrollButtons {
  display: none !important;
}

.clas {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.swal-button--confirm {
  background-color: var(--primary-color) !important;
}
.MuiInput-underline::after {
  border-bottom: 1px solid var(--primary-color) !important;
}
.box-all-tabs {
  width: 100% !important;
  /* height:40% !important; */
}
.box-all-tabs .MuiTabs-scroller > div button {
  z-index: 10 !important;
}
.MuiDataGrid-columnSeparator svg path {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.MuiTablePagination-root {
  /* display: none !important; */
}
.MuiPagination-root {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.MuiPagination-ul li button {
  color: var(--primary-color) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}
.MuiPagination-ul li:first-child button,
.MuiPagination-ul li:last-child button {
  background-color: rgb(255, 255, 255) !important;
  color: var(--primary-color) !important;
}
.MuiButtonBase-root.Mui-disabled.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-textPrimary.Mui-disabled.MuiPaginationItem-previousNext.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
  color: var(--primary-color) !important;
  background-color: rgb(255, 255, 255) !important;
}
.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-textPrimary.Mui-selected.MuiPaginationItem-page.css-1omp2b7 {
  background-color: var(--primary-color) !important;
  color: rgb(255, 255, 255) !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-1xr9krm.Mui-selected {
  background-color: var(--primary-color) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}
.divtitleone svg path {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.MuiPaper-elevation24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.4),
    0px 24px 38px 3px rgba(0, 0, 0, 0), 0px 9px 46px 8px rgba(0, 0, 0, 0) !important;
}
.add-company button {
  min-width: 200px !important;
}
.headerpoup {
  margin: 33px 0px 18px 0px !important;
}
.AddCompany {
  margin-bottom: 2% !important;
  margin-left: 3% !important;
}
.Addtypeseat {
  width: 100%;
}
.Addtypeseat > div {
  width: 17%;
}
.inputseatmatix input {
  width: 10% !important;
}
.card-emplyee {
  width: 200px !important;
}
.divtitlethree {
  width: 220px !important;
}
.tesr > div textarea:nth-child(1) {
  width: 100% !important;
  height: auto !important;
}
.MuiInputBase-root textarea:nth-child(1) {
  width: 100% !important;
  height: 20% !important;
}
.tesr > div textarea:nth-child(2),
.MuiInputBase-root textarea:nth-child(2) {
  display: none !important;
}
.MuiInputBase-root fieldset legend,
.jss27 {
  text-align: right !important;
}
.finishtrip {
  color: red !important;
  font-weight: bold !important;
  font-size: 10px !important;
}
.nofinishtrip {
  color: transparent !important;
  font-weight: bold !important;
  font-size: 10px !important;
}
.triptablewidth {
  width: 100% !important;
}
.field-table1-trip {
  width: 100% !important;
  display: flex !important;
  flex-direction: row-reverse !important;
}
.StyledTextInput-sc-k12n8x-0 {
  height: 38px !important;
}
.field-table1-trip > div:nth-child(1) {
  width: 25% !important;
  padding-right: 2% !important;
}
.trip{
  height: 20vh,
}
/* .field-table1-trip:nth-child(2) {
  width:40vw !important;
}  */
.triptablewidth1 {
  width: 100% !important;
}
.permincheckbox li span svg {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.permincheckbox li span svg path {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.div-progress .MuiFormControl-root {
  height: none !important;
  box-shadow: none !important;
}
.div-seat-one,
.div-seat-two,
.div-seat-three {
  width: 100% !important;
  justify-content: flex-start !important;
  margin-left: 0 !important;
}
.spinner {
  margin: 0px auto;
  width: 50px;
  height: 38px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 4px;
  display: inline-block;
  -webkit-animation: stretchDelay 1.2s infinite ease-in-out;
  animation: stretchDelay 1.2s infinite ease-in-out;
  margin-right: 3px;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.heart-div > svg path {
  color: #8a0e2b !important;
}
.heart-div-transit > svg path {
  color: #8a0e2b !important;
}
.heart-div2 > svg path {
  color: #fdd810 !important
}
@-webkit-keyframes stretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchDelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.box-all-permission {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 12%;
}
.box-triptype {
  height: 80vh;
  padding: 0px 2%;
  margin-bottom: 10px;
}
.box-triptype1 {
  height: 27vh;
}
.box-triptype2 {
  height: 65vh;
  padding: 0px 2%;
}
.autofocusenter:focus {
  outline: none !important ;
}
.autofocusenter.garden-focus-visible {
  outline: none !important ;
}
.box-all-tabs11 {
  margin-top: 0px !important;
  height: 73vh !important;
}
.box-all-tabs button {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #fff !important;
  font-size: 12px !important;
}
.css-1q2h7u5.Mui-selected {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}
.spinner1-box {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner1 {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 100px;
  height: 100px;
}
.spinner1 .path1 {
  stroke: #f9b917;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}
.Autobox .MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.Autobox .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3) !important;
  height: 42px !important;
}
.Autobox svg {
  display: none !important;
}
.ant-picker-dropdown {
  z-index: 10000000 !important;
}
.ant-picker {
  width: 100% !important;
  border: none;
  box-shadow: none !important;
  /* border-bottom: 1px solid #f9b917 !important; */
  border-radius: 0px !important;
}
.timetrip > .timediv > label {
  transform: translate(0%, 7px) scale(1) !important;
  z-index: 1000 !important;
}
.MuiDataGrid-rowCount {
  display: none !important;
}
.field-table1-trip
  .MuiDataGrid-virtualScrollerRenderZone
  .MuiDataGrid-row
  > .MuiDataGrid-cell.MuiDataGrid-cell--textCenter:focus {
  outline: solid #1976d2 1px !important;
}
.disabled-grid {
  pointer-events: none;
  filter: grayscale(10%);
  opacity: 0.5;
  /* Add any other styling you want for disabled state */
}
.disabled-grid:disabled {
  background: #dddddd;
}
.MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
  /* display: none !important; */
}
/* ==================================== */
.Box-Bus {
  position: relative;
  width: 99% !important;
  /* padding-right: 10px; */
  /* height: 50vh !important; */
  /* background: #fff !important; */
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.32); */
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  /* gap: 1%; */
  flex-wrap:wrap;
}

/* .seat-group {
  width: 8% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 4px;
} */
.seat-group {
  width: 99vw !important;
  height: 3.3vh !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-left: 4px;
  gap:1%
  /* flex-wrap: wrap; */
}
.seat-group div {
  box-shadow: none !important;
}
.box-number-bus {
  position: absolute;
  top: 50%;
  /* background-color: #eee; */
  width: 92%;
  padding: 23px 0px;
  border-radius: 10px;
  text-align: center;
}
.ticketrip .MuiOutlinedInput-input {
  padding: 6.5px 4px !important;
}
.datetrip > div {
  border: 1px solid #000;
  padding: 3px 0px !important;
  border-radius: 5px !important;
  /* border:8px !important; */
}
.datetrip label {
  margin-right: 20px !important;
}
.datetrip button {
  margin-right: 10px !important;
}
.timetrip2 > div {
  border: 1px solid #000;
  padding: 8px 0px !important;
  border-radius: 5px !important;
  /* border:8px !important; */
}
.timetrip2 label {
  margin-right: 20px !important;
  margin-top: 10px !important;
}

.timetrip2 button {
  margin-right: 10px !important;
}
.box-button-remove-trip-pass > .MuiSvgIcon-root {
  margin-top: 15px;
}
.input-seats .MuiOutlinedInput-input {
  padding: 7.5px 14px;
}
.add-passenger .MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}
.select-seat fieldset {
  border: none !important;
}
.select-seat .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.deletticket
  > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: red !important;
}
.tab-multi-company {
  height: 73vh;
  margin-top: 10vh;
}
.box-logo-company {
  height: 10vh;
}
.price-ticket-trip-ticket > label .MuiInputLabel-shrink {
  transform: translate(1px, -6px) scale(1) !important;
  /* font-size: 10px !important */
}
.div-type-seat .MuiFormControl-root {
  height: 100% !important;
  margin-bottom: 0px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: baseline !important;
}
.container-div-filter-trip > div {
  margin-bottom: 1px !important;
}
.trip-select-type .MuiFormControl-root {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0px !important;
}
.select1tripdirect > div,
.select2tripdirect > div {
  height: 35px !important;
}
.select1tripdirect {
  width: 100% !important ;
  margin-right: 0px !important;
}
.select2tripdirect {
  width: 100% !important ;
  margin-right: 0px !important;
}
.filterdate {
  height: 36px !important;
  padding: 0px !important ;
}
.filterdateBlocked {
  height: 36px !important;
  padding: 0px !important ;
}
.filterdate input {
  padding: 0px !important ;
  height: 16px !important;
  font-size: 9px !important;
}
.filterdateBlocked input {
  padding: 0px !important ;
  height: 16px !important;
  font-size: 9px !important;
}

.box-all-tabbs {
  z-index: 100 !important;
}
.box-all-sidbar {
  z-index: 101 !important;
}
.reloadertripdirect {
  height: 22px !important;
}

.div-sidebar-company {
  width: 15%;
  height: 85vh;
  position: absolute;
  overflow:auto;
  scrollbar-color:var(--primary-color) transparent !important;
  scroll-behavior: smooth !important;
  scrollbar-width:thin !important;
}
.tiptransitdiv{
  border:1px solid var(--secound-color);
  padding:0px 5px ;
  color:#fff;
  background:var(--secound-color);
  font-size:10px;
  display:flex;
  justify-content: center;
  align-items:center;
  margin-left:0.5%
}
/* ==================================== */
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.MuiPaper-root .MuiList-root.MuiMenu-list.MuiList-padding {
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  font-family: "Roboto",Helvetica,Arial,sans-serif !important;
  flex-direction:column
  /* height:40vh !important ; */
}
.MuiPaper-root .MuiList-root.MuiMenu-list.MuiList-padding > li {
  display: flex !important;
  justify-content: end ;
  align-items: flex-start !important;
  width:100%;
  font-family: "Roboto" !important;
  font-size:14px !important ;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: none !important;
  /* padding-top: 6px; */
  white-space: nowrap !important;
  letter-spacing: none !important;
  
}
.aad-ticket{
  width: 8% !important;
}
.rightbox{
  width:85%;
}
.leftbox{
  width:13%;
}
@media (min-width: 859px) and (max-width: 1245px) {
  .css-204u17-MuiDataGrid-main {
    /* margin-top: 80px !important; */
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .pro-sidebar .pro-menu {
    width: 100% !important;
  }
  .aad-ticket{
    width: 15% !important;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span {
    font-size: 12px !important;
  }
  .div-sidebar-company {
    width: 30% !important;
    height: 71vh;
    position: absolute;
  }
  .pro-sidebar {
    width: 100% !important;
    min-width: 100% !important;
    background: var(--secound-color) !important;
  }
  .pro-sidebar-inner {
    width: 100% !important;
    padding: 0px 0px !important;
  }
  .MuiFormControl-root {
    margin-bottom: 20px !important;
    height: 20% !important;
  }
  .headerpoup {
    margin: 19px 0px 18px 0px !important;
  }
  .AddCompany {
    margin-bottom: 5% !important;
    margin-left: 5% !important;
  }
  .div-progress {
    position: absolute !important;
    right: 30% !important;
  }
  .travelsectiontwo > div {
    width: 100% !important;
  }
  .search-deactive-user {
    height: 38px !important;
    max-height: 38px !important;
  }
  .selected-role-filter {
    margin-right: 3px !important;
  }
  .select-div {
    margin-left: 15px !important;
  }
  .title-card {
    font-size: 18px !important;
  }
  .box-catergory-permistion {
    font-size: 16px !important;
  }
  .box-all-permission {
    margin-left: 2% !important;
  }
  .box-notic {
    font-size: 20px !important;
  }
  .field-table1-trip {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .field-table1-trip > div:nth-child(1) {
    width: 100% !important;
    padding-right: 2% !important;
  }

  .field-table1-trip > div:nth-child(2) {
    width: 96% !important;
    /* padding-right: 2% !important; */
  }
  .trip{
    height: 35vh,
  }
  .box-triptype1{
    height: 45vh !important;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .custom_sidebar {
    /* width: 100% !important; */
    /* background: var(--secound-color) !important; */
    height: 8vh;
  }

  .pro-sidebar {
    width: 100% !important;
    min-width: 100% !important;
    background: var(--secound-color) !important;
  }
  .pro-sidebar-inner {
    width: 100% !important;
  }
  .div-progress {
    position: absolute !important;
    right: 25% !important;
  }
}
@media (min-width: 576px) {
  .filterdate >div{
    width:95% !important;
  }
  .filterdateBlocked >div{
    width:95% !important;
  }
  .eIrzyD {
    width:95% !important;
    max-width:95% !important;
    flex: 0 0 95% auto !important;
    padding-left: 2px !important;
  }
  .GdFUr {
    width:95% !important;
    max-width:95% !important;
    flex: 0 0 95% auto !important;
    padding-left: 2px !important;
  }
  .filterdate input{
    background-color:transparent;
    min-height:25px !important;

  }
  .filterdateBlocked input{
    background-color:transparent;
    min-height:25px !important;

  }
}
@media (min-width: 1280px) and (max-width: 1556px) {
  .selects4 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  .selects3 .MuiSelect-icon {
    right: 86% !important;
    fill: #818e94 !important;
  }
  .selects2 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  .selects2trip .MuiSelect-icon {
    /* right: 85% !important; */
    fill: #818e94 !important;
  }
  .selects1 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  .selects1trip .MuiSelect-icon {
    /* right: 85% !important; */
    fill: #818e94 !important;
  }
  .selects11 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  .selects11trip .MuiSelect-icon {
    /* right: 85% !important; */
    fill: #818e94 !important;
  }
  .selects21 .MuiSelect-icon,
  .selects23 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  .selects321 .MuiSelect-icon,
  .selects3211 .MuiSelect-icon {
    right: 85% !important;
    fill: #818e94 !important;
  }
  /* .selects1::before,
  .selects21::before,
  .selects321::before,
  .selects2::before,
  .selects3::before,
  .selects4::before {
    right: 18% !important;
  } */
  .selects3 {
    width: 100% !important;
    background-image: url(http://localhost:3000/static/media/Send.5d9ea41289c42fa1bcdc.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    background-size: 20px;
    display: flex !important;
    align-items: first baseline !important;
  }
  .selects321 {
    /* width: 80% !important; */
    background-image: url(http://localhost:3000/static/media/Send.5d9ea41289c42fa1bcdc.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    background-size: 20px;
    display: flex !important;
    align-items: first baseline !important;
  }
  .selects4 {
    width: 108% !important;
    background-image: url(http://localhost:3000/static/media/Received.53ebe432da27f2b76de3.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    background-size: 20px;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 13px;
  }
  .selects1 {
    width: 90% !important;
    background-image: url(http://localhost:3000/static/media/sort.26b9b4a7bb7ffbd241a4.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    /* margin-right: 33px; */
  }
  .selects1trip {
    width: 100% !important;
    /* background-image: url(http://localhost:3000/static/media/sort.26b9b4a7bb7ffbd241a4.svg) !important; */
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 0px;
  }
  .selects21 {
    width: 80% !important;
    background-image: url(http://localhost:3000/static/media/sort.26b9b4a7bb7ffbd241a4.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 33px;
  }
  .selects11 {
    width: 80% !important;
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 33px;
  }
  .selects11trip {
    width: 80% !important;
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 33px;
  }
  .select1tripdirect {
    width: 100% !important ;
    margin-right: 0px !important;
  }
  .selects321 {
    /* width: 80% !important; */
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
    margin-right: 5px;
  }
  .selects2 {
    width: 90% !important;
    background-image: url(http://localhost:3000/static/media/sort.26b9b4a7bb7ffbd241a4.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
  }
  .selects2trip {
    width: 100% !important;
    /* background-image: url(http://localhost:3000/static/media/sort.26b9b4a7bb7ffbd241a4.svg) !important; */
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    display: flex !important;
    align-items: first baseline !important;
  }

  .select1tripdirect > div {
    height: 30px !important;
  }
  .select2tripdirect > div {
    height: 30px !important;
  }
  .StyledTextInput-sc-k12n8x-0 {
    height: 38px !important;
    min-height: 38px !important;
  }
  .filterdate {
    height: 30px !important;
    padding: 1px;
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .filterdateBlocked {
    height: 35px !important;
    padding: 1px;
    margin-left: 4px !important;
    margin-right: 4px !important;
  }


  .reloadertripdirect {
    height: 20px !important;
  }
  .select1tripdirect::before,
  .select2tripdirect::before {
    /* top: 0px !important; */
  }
  .bottonFullwidth1 > button {
    height: 38px !important;
    margin-bottom: 10px;
  }
  .bottonFullwidth1 > button > span {
    /* font-size:10px !important */
  }
  /* .tab-multi-company {
  height:40vh !important;
} */
  .box-number-bus {
    position: absolute;
    top: 38%;
    /* background-color: #eee; */
    width: 90%;
    padding: 23px 0px;
    border-radius: 10px;
    text-align: center;
  }
  .datetrip .MuiFormControl-root {
    height: 38% !important;
    margin-bottom: 0px !important;
  }
  .datetrip .MuiFormControl-root label {
    margin-top: -10px;
  }
  .datetrip .MuiFormControl-root .MuiInputBase-root {
    margin-top: 0px;
  }
  .timetrip1 .MuiFormControl-root {
    height: 38% !important;
    margin-bottom: 0px !important;
  }
  .timetrip1 .MuiFormControl-root label {
    margin-top: -10px;
  }
  .timetrip1 .MuiFormControl-root .MuiInputBase-root {
    margin-top: 0px;
  }
  .triptablewidth1 {
    height: auto !important;
  }
  .triptablewidth1 > div,
  .triptablewidth1 > div > div,
  .triptablewidth1 > div > div > .create-passenger {
    height: 100% !important;
  }
  /* .triptablewidth1 .create-passenger > div:nth-child(2) {
    height: 6% !important;
    margin-top: 80px;
  } */
}
@media (min-width: 1280px) and (max-width: 1556px) {
  .custom_sidebar {
    /* width: 100% !important; */
    /* background: var(--secound-color) !important; */
  }
  .content {
    width: 100% !important;
    overflow: auto;
    height: 90vh;
    margin-top: 2vh !important;
  }
  .pro-sidebar {
    width: 100% !important;
    min-width: 100% !important;
    background: var(--secound-color) !important;
  }
  .pro-sidebar-inner {
    width: 100% !important;
    padding: 0px !important;
  }
  .div-progress {
    position: absolute !important;
    right: 20% !important;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .typeseatmatrix {
    flex-direction: column-reverse !important;
  }
  .rightbox{
    width:70%;
  }
  .leftbox{
    width:30%;
  }
  .inputseatmatix input {
    width: 100% !important;
  }
  .selects21 {
    width: 90% !important;
    margin-right: 7%;
    margin-left: 0px !important;
  }
  .selects23 {
    width: 90% !important;
    margin-right: 7%;
    margin-left: 0px !important;
  }
  .busseatmatrix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .admin-filter .Autobox .MuiOutlinedInput-notchedOutline {
    width: 100%;
  }
  .MuiTypography-h5 {
    font-size: 1.3rem !important;
  }
}
.MuiTypography-h6 {
  font-weight: lighter !important;
}
@media (min-width: 1280px) and (max-width: 1536px) {
  .typeseatmatrix {
    flex-direction: row !important;
  }
  .inputseatmatix input {
    width: 10% !important;
  }
  .busseatmatrix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 10%; */
  }
  .tesr > label button {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
  }
  .iconplaceholder {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
  }
  .MuiFormControl-root {
    margin-bottom: 20px !important;
    height: 20% !important;
  }
  .box-catergory-permistion.MuiGrid-grid-lg-3 {
    flex-grow: 0;
    max-width: 45%;
    flex-basis: 45%;
  }
  .box-all-permission {
    justify-content: space-between !important;
    gap: 0% !important;
  }
  /* .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-lg-12
    > .MuiPaper-rounded {
    height: 98px !important;
  } */
  .box-triptype {
    height: 85vh !important;
    padding: 0px 2%;
  }
}
@media (min-width: 768px) and (max-width: 1536px) {
  .tesr > label button {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
  }
  .iconplaceholder {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
  }
  .MuiFormControl-root {
    margin-bottom: 20px !important;
    height: 20% !important;
  }
  .add-date .MuiFormControl-root {
    margin-bottom: 20px !important;
    height: 60% !important;
  }
  .filterdate {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 7px;
  }
  .filterdateBlocked {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 7px;
  }
  .timetrip2 > div {
    border: 1px solid #000;
    padding: 0px 0px !important;
    border-radius: 5px !important;
    height: 48% !important;
    margin-bottom: 40px !important;
  }
  .timetrip2 label {
    margin-top: 0px !important;
  }
  .reservation-manager {
    font-size: 12px !important;
    padding:1% 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1536px) {
  .MuiFormControl-root {
    margin-bottom: 25px !important;
    height: 20% !important;
  }
  .travelsect .MuiFormControl-root{
    margin-bottom: 20px !important;
    height: 11% !important;
  
  }
  .MuiDialogContent-root{
    overflow: none;
  }
  div
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.iconplaceholder.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    width: 20px;
    height: 20px;
  }
  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
  .logindiv .MuiFormControl-root input {
    padding: 10.5px 14px !important;
  }
  .logindiv > div{
    display:flex !important;
    justify-content:flex-start !important;
    align-items:baseline !important
  }
  .AddCompany {
    margin-bottom: 5% !important;
    margin-left: 5% !important;
  }
  .headerpoup {
    margin: 19px 0px 18px 0px !important;
  }
}
@media (min-width: 570px) and (max-width: 768px) {
  .MuiFormControl-root {
    margin-bottom: 25px !important;
    height: 40% !important;
  }
  .travelsect .MuiFormControl-root{
    margin-bottom: 20px !important;
    height: 11% !important;
  
  }
  .MuiDialogContent-root{
    overflow: none;
  }
  div
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.iconplaceholder.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    width: 20px;
    height: 20px;
  }
  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
  .logindiv .MuiFormControl-root input {
    padding: 10.5px 14px !important;
  }
  .logindiv > div{
    display:flex !important;
    justify-content:flex-start !important;
    align-items:baseline !important
  }
  .AddCompany {
    margin-bottom: 5% !important;
    margin-left: 5% !important;
  }
  .headerpoup {
    margin: 19px 0px 18px 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .selects1,
  .selects11 {
    margin-bottom: 15px;
  }
  .selects1trip,
  .selects11trip {
    margin-bottom: 15px;
  }
  .selects21,
  .selects23 {
    margin-bottom: 15px;
  }
  .selects321,
  .selects2311 {
    margin-bottom: 15px;
  }

  .selects2 {
    margin-bottom: 15px;
  }
  .selects2trip {
    margin-bottom: 15px;
  }

  .selects3 {
    margin-bottom: 15px;
  }

  .selects4 {
    margin-bottom: 15px;
  }
  .Addtypeseat > div {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    width: 100px;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span {
    font-size: 10px !important;
  }
  .MuiFormLabel-root {
    font-size: 12px !important;
  }
  .MuiTypography-h5 {
    font-size: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .placeholdertitle1 {
    display: block;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px !important;
  }
  .add-passenger .MuiOutlinedInput-input {
    padding: 7.5px 14px !important;
  }
  .add-passenger.MuiFormControl-root {
    margin-bottom: 20px !important;
    height: 10% !important;
  }
  #demo-form-control-label-placement {
    font-size: 0.8rem !important;

    letter-spacing: 0.04em !important;
  }
  .css-icy3u6 .pro-sidebar-inner {
    padding: 0px 0px !important;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span {
    font-size: 10px !important;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    min-width: 30px !important;
    font-size:12px !important;
  }
  .blocked svg , .locked svg {
    width: 15px !important;
  }
}

@media (min-width: 859px) and (max-width: 1600px) {
}
@media print {
  .pro-sidebar > .pro-sidebar-inner {
    display: none !important;
  }
  .pro-sidebar {
    display: none !important;
  }
  .custom_sidebar {
    width: 0% !important;
  }
  .tablepassenger {
    width: 100% !important;
  }
  .datapassenger {
    width: 100% !important;
  }
  .content {
    width: 100% !important;
    margin-top: 2vh !important;
  }
  .css-733vbh-MuiButtonBase-root-MuiListItemButton-root {
    padding-right: 0 !important;
  }
  /* Additional adjustments for printing */
  /* For example, you might want to adjust font sizes, margins, etc. */
}
@media (min-width: 768px) and (max-width: 1800px) {
  .tab-multi-company {
    margin-top: 15.2vh;
  }
  .box-logo-company {
    height: 15vh;
  }
  .body-container2 span {
    font-size: 15px !important;
  }
  .body-container2 .divtitletwo span {
    font-size: 12px !important;
  }
  /* .pro-sidebar .pro-menu {

    width: 100% !important;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span{
    font-size:12px !important;
  } */
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .title-bus-type {
    font-size: 25px;
    color: var(--primary-color) !important;
  }
  .box-notic {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 20px;
  }
  .pro-sidebar .pro-menu {
    width: 100% !important;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span {
    font-size: 12px !important;
  }
  .pro-sidebar .pro-menu a .MuiListItemText-root span {
    font-size: 12px !important;
  }
  .pro-sidebar-inner {
    padding: 0px !important;
  }
}

.css-vubbuv {
  color: var(--primary-color);
}
.admin-filter .Autobox .MuiOutlinedInput-notchedOutline {
  height: 40px !important;
  width: 90%;
}
.delete-company > path {
  color: #cd090a !important;
}
.load {
  border-radius: 10px !important;
}
.loaddelete {
  border-radius: 3px !important;
  width: 70% !important;
  padding: 0% 0;
}
.loaddelete1 {
  border-radius: 3px !important;
  width: 50% !important;
  padding: 1% 0;
}
.loadlogin {
  border-radius: 25px !important;
  width: 36%;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  padding: 0px !important;
}
.field-table1-trip .MuiDataGrid-columnHeaders {
  font-family: "Arial", sans-serif; /* Change font family as needed */
  font-weight: bold;
  font-size: 13px !important; /* Adjust the size as needed */
}

.field-table1-trip .MuiDataGrid-cell,
.field-table1-trip .MuiDataGrid-cellContent {
  font-family: "Arial", sans-serif; /* Change font family as needed */
  font-size: 13px !important; /* Adjust the size as needed */
  /* font-weight: bold; */
}
.css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  font-size: 13px; /* Adjust the size as needed */
}
.autofocusenter > img {
  width: 15px !important;
}
.field-table1-trip .MuiDataGrid-cell > .MuiDataGrid-cellContent {
  font-size: 13px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.MuiDataGrid-columnHeadersInner {
  font-size: 13px !important;
}

.field-table1-trip .MuiDataGrid-row {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table1-trip .MuiDataGrid-main{
  overflow-x:auto !important;
}
.field-table1-trip .MuiDataGrid-main > .MuiDataGrid-columnHeaders {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table1-trip .MuiDataGrid-main > .MuiDataGrid-columnHeaders path{
  fill: transparent !important;
}
.field-table1-trip .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  /* min-height:50vh !important; */
  /* max-height:auto !important; */
  margin-top: 30px !important;
  /* overflow:scroll !important; */
}

.field-table1-trip .MuiDataGrid-main .MuiDataGrid-virtualScrollerContent {

  height:auto !important;
  overflow-x:auto !important;
}
/* MuiDataGrid-virtualScrollerContent */
.css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  max-height: 30px !important;
  min-height: 30px !important;
}
.width-button-trip > button {
  height: 38px !important;
}
.reloadertripdirect {
  height: 30px !important;
}
.field-table .MuiDataGrid-cell > .MuiDataGrid-cellContent {
  font-size: 13px !important;
}
.MuiDataGrid-columnHeadersInner {
  font-size: 13px !important;
}

.field-table .MuiDataGrid-row {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table .MuiDataGrid-main > .MuiDataGrid-columnHeaders {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  height: 60vh !important;
  margin-top: 30px !important;
}
.field-table .MuiDataGrid-main  .MuiDataGrid-virtualScrollerContent{
  height: 100% !important;
  /* margin-top: 30px !important; */
}
.schedular-table .MuiDataGrid-row {
  max-height: 37px !important;
  min-height: 37px !important;
}
.MuiFormLabel-root {
  font-size: 13px !important;
}
.title-dialog {
  font-size: 16px !important;
}
#demo-simple-select,
.MuiInputBase-input {
  font-size: 12px !important;
}
.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 12px !important;
}
.box-button-add-certifcation span {
  font-size: 12px;
  cursor: pointer;
}
.image-in {
  width: 12px;
  margin-left: 10px;
}
.searchDesign1 {
  margin-bottom: 20px !important;
  height: 33px !important;
}

.MuiAutocomplete-root {
  height: 100% !important;
}
.Autobox .MuiFormControl-root > #combo-box-demo-label{
  top:-10px !important;
}
.Autobox .MuiFormControl-root > .MuiInputBase-root{
  padding:0px !important;
}
.Autobox .MuiFormControl-root > .MuiInputBase-root .MuiOutlinedInput-input{
  padding: 5px 10px !important;
}
.add-passenger.MuiFormControl-root{
  margin-bottom: 20px !important;
  height: 20px !important;

}
.code_seat.MuiFormControl-root{
  margin-top: 10px !important;

}
.blocked span{
  color:black;
  cursor:pointer;
}
.locked span{
  color:red;
  cursor:pointer
}

.shortcut p{
  font-size:15px;
  line-height:1.7;
}
.shortcut div{
  display: flex;
  flex-direction:row-reverse;;
  justify-content: space-between;
  align-items: center;
  width:100%;
}
.css-iclwpf .MuiDataGrid-columnHeader{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
}
.css-vcjdx3{
  height: 30px !important;
  width: 100% !important;
  max-height: 30px !important;
  min-height: 30px !important;
}
.MuiDataGrid-columnHeadersInner, .field-table .MuiDataGrid-cell > .MuiDataGrid-cellContent{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  display: flex !important;
  justify-content:center !important;
  align-items:center !important;
  
}
.css-iclwpf .MuiDataGrid-row{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;

}
.css-iclwpf .MuiDataGrid-cell{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
}
.css-iclwpf .MuiDataGrid-columnHeader{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;

}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader{
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;

}
.MuiDataGrid-main{
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  /* overflow:scroll !important; */
}
.MuiDataGrid-columnHeadersInner{
  max-height: 30px !important;
  min-height: 30px !important;
}
/* ================= */
.css-k008qs {
  display: flex;
  width: 100% !important;
}
.heart-div,.heart-div2{
  position: absolute;
  left:0px ;
  display:flex;
  padding: 0 0 0 1%;
}
.heart-div-transit{
  position: absolute;
  left:0px ;
  display:flex;
  padding: 0 0 0 1%;
  top:-3%
}
.heart-div svg,.heart-div2 svg,.heart-div-transit svg{ width:16px}
.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone{
  /* height:100% !important; */
}
/* .field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  height: 24vh !important;
  margin-top: 30px !important;
  position: fixed;
}
.field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent ,.MuiDataGrid-virtualScrollerContent{
  
  height: auto !important;
  min-height: auto !important;
  max-height: auto !important; 
  position: absolute;
  height: 100vh  !important;

} */

/* .field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  height: 24vh !important;
  max-height: 30%;
  overflow-y: auto; 
  position: fixed;
}

.field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent {
  position: absolute;
  top: 0px;
  height: calc(100vh - 60vh);
  margin-top: calc(100vh - 20vh);
} */
.field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  height: 21vh !important; /* Remove fixed height to allow natural container height */
  overflow-y: auto; /* Enables scrolling */
  position: relative; /* Changed from fixed to relative */
}

.field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent {
  position: relative; /* Changed from absolute which can disconnect the height from its container */
  top: 0;
  /* height: 100vh !important; */
}

/* Additional styles to ensure DataGrid fills its container */
.box-triptype1 {
  height: 21vh; /* Set the height to 100% to fill its container */
  display: flex;
  flex-direction: column; /* This will stack children vertically */
}

/* Ensure that your outermost container fills the available space */
.triptablewidth {
  /* height: calc(100vh - 27vh);  */
  width: 100%; 
}
.field-table .MuiDataGrid-cell > .MuiDataGrid-cellContent {
  font-size: 13px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.MuiDataGrid-columnHeadersInner {
  font-size: 13px !important;
}

.field-table3 .MuiDataGrid-row {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table3 .MuiDataGrid-main > .MuiDataGrid-columnHeaders {
  max-height: 30px !important;
  min-height: 30px !important;
}
.field-table3 .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  /* min-height: 30vh !important; */
  /* height: 27vh !important; */
  /* max-height: 70vh !important; */
  margin-top: 30px !important;
  /* overflow:scroll; */
}
.field-table3 .MuiDataGrid-cell > .MuiDataGrid-cellContent {
  font-size: 13px !important;
}
  .MuiDataGrid-columnHeadersInner, .field-table3 .MuiDataGrid-cell > .MuiDataGrid-cellContent{
    height: 30px !important;
    max-height: 30px !important;
    min-height: 30px !important;
    
  }
  .field-table3 {
    width: 100% !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    height: 21vh !important;
  }

  .field-table3 > div:nth-child(1) {
    width: 25% !important;
    padding-right: 2% !important;
  }
  #menu-id_seat_type .MuiPopover-paper{
    /* width:16% !important;
    height:60% !important;
    top: 33% !important;
  left: 36% !important; */
  }
  .jss73{
    padding:0px !important;
  }
  .testtt div:nth-child(3) {
    width: 200px !important;
    border-radius: 0px !important;
    padding: 23px 24px 19px 0px;
    margin-top: 15px;
    display: flex !important;
    justify-content: start !important;
  }
  
  .testtt div:nth-child(3) ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width:100% !important;
  }
  
  .testtt div:nth-child(3) ul li {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 13px !important;
    display: flex !important;
    justify-content: start !important;
    align-items: start !important;
  }
  
  .testnew div:nth-child(3) {
    width: 190px !important;
    border-radius: 0px !important;
    margin-top: 45px;
    height: 110px;
    display: flex !important;
    justify-content: start !important;
    align-items: start !important;
  }
  
  .testnew div:nth-child(3) ul {
    padding-left: 0 !important;
    padding-right: 4 !important;
    display: flex !important;
    justify-content: start !important;
    align-items: start !important;
    width:100% !important;
  }
  
  .testnew div:nth-child(3) ul li {
    padding-top: 5 !important;
    padding-left: 0 !important;
    padding-right: 4 !important;
    padding-bottom: 5px !important;
    font-size: 12px;
    display: flex !important;
    justify-content: start !important;
    align-items: start !important;
    
    /* padding: 0 !important; */
  }
  .MuiInputBase-inputMultiline{
    padding:0px !important;
  }
  .swal-text{
    text-align: center !important;
  }
  .button-setting{
    background-color: rgb(249, 185, 23);
    color: rgb(255, 255, 255);
    padding: 0px 0.5vw;
    margin-left: 0.5vw !important;
    cursor:pointer;
   
  }
  .button-setting-disabled{
    background-color: rgba(166, 165, 163, 0.57);
    color: rgb(255, 255, 255);
    padding: 0px 0.5vw;
    margin-left: 0.5vw !important;
    cursor:pointer;
   
  }
  .search-employee-multi-select .MuiDataGrid-footerContainer,
  .search-employee-multi-select .MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(3),
  .search-employee-multi-select .MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(3){
    display:block !important;
  }
  .search-employee-multi-select{
    height:40px;
    border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  direction:rtl
  /* margin-left:2%, */
  }
  .search-employee-multi-select label + .MuiInput-formControl {margin-top:11px !important}
  .search-employee-multi-select label {

    margin-top:-10px !important
  }
  .table-accounting .field-table .MuiDataGrid-footerContainer{
    display:block !important;
    margin-top:10px !important ;
  }
  .scroll-accounting .field-table .MuiDataGrid-main{
    /* Hide scrollbar but allow scrolling when needed */
    overflow-y: scroll !important;
  overflow-x: scroll !important;
  }
  
  .table-accounting .field-table .MuiDataGrid-window::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
#menu-select4-type-bus .MuiPaper-root,

#menu-id_seat_type .MuiPaper-root{
  width:15% !important;
  min-height:60% !important;
}
.MuiPaper-root .MuiList-root.MuiMenu-list.MuiList-padding > li > div{
  height:70vh !important;

}
.MuiDataGrid-main .MuiDataGrid-columnHeaders,
.table-accounting .field-table .MuiDataGrid-main .MuiDataGrid-virtualScroller{ overflow: unset !important;}
.hr-seat{
  height: 4%;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.swal-footer{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.blocked , .locked{
  cursor:pointer;
}
.blocked svg path{
  color:black;
}
.locked svg path{
  color:red;
}